import 'reflect-metadata'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '@/assets/css/main.css'
import '@/assets/scss/main.scss'
import 'ant-design-vue/dist/antd.css'
import 'piramis-base-components/src/assets/scss/main.scss'

import { NavigationGuardNext, Route } from 'vue-router'
import Vue, { PluginFunction, VNode } from 'vue'

import { DynamicReactiveRefs, ReactiveRefs } from 'vue-reactive-refs'
import VueScreen from 'vue-screen'
import App from './App.vue'
import i18n from './i18n/i18n'
import router from './router/index'
import store from './store/store'
import isMobile from 'piramis-js-utils/lib/isMobile'

import RouteHelpers from 'piramis-base-components/src/plugins/RouteHelpers/index'
import AdminTemplate from 'piramis-base-components/src/plugins/AdminTemplate'
import { DynamicHeader, MenuItemType } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem'
import { AdminTemplateOptions, Layout } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/Config'
import { ProductNames } from 'piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { hasMatchedRouteParams } from 'piramis-base-components/src/components/SelectEntityWizard/includes/helpers'

import Antd from 'ant-design-vue'
import VueCustomElement from 'vue-custom-element'
import moment from 'moment'
import { Guid } from 'guid-typescript'

import { canCreatePost, hasChangeConfigPermission } from '@/includes/PermissionHelper'
import '@/views/PostFeed/assets/styles.css'
import { SlimBoardInfo, SlimChannelInfo, TestBoardStateCode } from '@/includes/types/Board.types'
import PostFeed from '@/views/PostFeed/PostFeed.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import {
    actionTypeBreadcrumb,
    baseBoardBreadcrumbs,
    baseChannelBreadcrumbs
} from '@/includes/AppConfigBreadcrumbsHelper'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import { errorNotification } from '@/includes/services/NotificationService'
import TokenId from 'piramis-base-components/src/shared/utils/TokenId'

Vue.use(RouteHelpers as unknown as PluginFunction<any>, { router })

Vue.use(VueScreen, {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
});

Vue.use(DynamicReactiveRefs)
Vue.use(ReactiveRefs)

Vue.use(Antd)

const boardsDynamicHeader = (): Array<Record<'menuItem', DynamicHeader>> => {
    return store.getters.sortedBoards.map(board => {
        const boardId = TokenId.getId(board.board)

        return {
            menuItem: {
                type: MenuItemType.DynamicHeader,
                isVisible: [
                    (ctx) => !!ctx && !ctx.route.params[ EntityTypes.BOT_ID ] && !ctx.route.params[EntityTypes.CHANNEL_ID],
                ],
                header: () => board.title,
                avatar: () => board.avatar,
                to: {
                    name: 'posts_planner',
                    params: {
                        [ EntityTypes.BOT_ID ]: boardId
                    },
                    query: {
                        ...!canCreatePost(board.permissions) ?
                            { calendar: CalendarPostsSource.Suggested } :
                            { calendar: CalendarPostsSource.Schedule }
                    }
                }
            }
        }
    })
}

const adminTemplateOptions: AdminTemplateOptions = {
    config: {
        footer: {
            version: store.state.metaModule.PACKAGE_VERSION,
            ourProducts: {
                exclude: [
                    ProductNames.PRESSCODEBOT
                ],
            }
        },
        meta: {
            projectName: 'PressCode.app',
            apiUrl: 'api.presscode.app/cabinet',
            projectDomain: 'https://presscode.app',
            sidebarLogo: require('./assets/images/presscode-logo/presscodebot_logo.svg'),
            loginPageLogo: require('./assets/images/presscode-logo/logo_title.png'),
            tgResolvers: {
                supportBotDomain: 'PressCodeSupportRu',
                promotionBotDomain: 'ChatKeeperPromotionBot',
                mainBotDomain: 'Presscodebot',
            },
            login: {
                botAuthLink: 'https://t.me/PresscodeBot?start=cabinetlink',
                loginWidget: {
                    botLogin: 'presscodebot',
                    tgWidgetAuthLink: 'u',
                }
            },
            languageCodes: [ 'ru', 'en' ],
            isSaveButtonVisible: false,
            paymentEntity: {
                target_type: (ctx) => {
                    if (store.state.boardsState.boards) {
                        const currentBoard: SlimBoardInfo | null | undefined = store.getters.getBoardById(ctx.route.params[ EntityTypes.BOT_ID ])

                        if (currentBoard) {
                            const channel_id = ctx.route.params[EntityTypes.CHANNEL_ID]

                            return {
                                target_id: {
                                    board_key: currentBoard.board,
                                    ...channel_id ? { channel_id } : {},
                                },
                                target_type: channel_id ? 'channel' : 'board'
                            }
                        }
                    }

                    throw new Error('Boards are not initialised')
                }
            },
        },
        loyaltySystem: {
            partnerBlock: {
                partnerLink: (localeCode) => 'https://t.me/ChatKeeperPromotion/?start=loyalty_parnters'
            },
            companiesBlock: {
                termsOwnerLink: (localeCode) => 'https://t.me/ChatKeeperPromotion/?start=loyalty_parnters'
            }
        },
        companyRegistration: {
            powerOfAttorney: (localeCode): string => 'https://presscode.app/wp-content/uploads/2022/03/power_of_attorney_ur.doc',
            howToRegisterUr: (localeCode): string => 'https://presscode.app/wp-content/uploads/2022/03/how_to_register_ur.docx',
        },
        search: {
            entities: (ctx) => {
                const boards = store.state.boardsState.boards

                if (boards !== null && Array.isArray(boards) && boards.length) {
                    return (boards as Array<SlimBoardInfo>).map((b) => {
                        return {
                            id: TokenId.getId(b.board),
                            avatar: b.avatar,
                            title: b.title
                        }
                    })
                } else {
                    return []
                }
            },
            path: (ctx, router, selectedId): void => {
                if (selectedId !== null && ctx.route.params[ EntityTypes.BOT_ID ] !== selectedId.toString()) {
                    const currentBoard: SlimBoardInfo | null | undefined = store.getters.getBoardById(selectedId)

                    store.dispatch('getBoardConfig', currentBoard!.board)
                        .then(() => {
                            router.push({
                                name: 'posts_planner',
                                params: {
                                    [ EntityTypes.BOT_ID ]: selectedId.toString(),
                                }
                            })

                            store.dispatch('clearBotStores')
                        })
                }
            }
        },
        serviceData: {
            type: 'ServiceData',
            tariffs: {
                Business: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/business.png'),
                    color: 'rgb(177,69,250)',
                },
                Prime: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/business.png'),
                    color: 'rgb(177,69,250)',
                },
            },
        },
        topMenu: [
            {
                menuItem: {
                    type: MenuItemType.Component,
                    isVisible: [],
                    component: () => import('@/components/SuggessNotifications.vue')
                }
            },
            {
                menuItem: {
                    guid: Guid.create().toString(),
                    type: MenuItemType.DropDown,
                    name: 'help',
                    featherIcon: true,
                    icon: 'help-circle',
                    isVisible: [ true ],
                    class: [ 'text-warning' ],
                    submenu: [
                        {
                            menuItem: {
                                type: MenuItemType.DropDownLink,
                                isVisible: [ true ],
                                url: () => 'https://www.youtube.com/@user-gz2go8dj9o',
                                featherIcon: true,
                                icon: 'youtube',
                                name: 'help_youtube'
                            }
                        },
                        {
                            menuItem: {
                                isVisible: [ true ],
                                type: MenuItemType.DropDownLink,
                                name: 'help_tech_support',
                                icon: 'life-buoy',
                                featherIcon: true,
                                url: () => 'https://t.me/PressCodeSupportRu'
                            },
                        },
                    ],
                },
            }
        ],
        profile: {
            extraAuth: true
        },
        sidebarMenu: [
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layout',
                    isVisible: [ true ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Boards',
                            path: '',
                            component: (): any => import('./views/boards.vue'),
                        },
                    },
                    class: [],
                },
            },
            boardsDynamicHeader,
            {
                menuItem: {
                    type: MenuItemType.DynamicHeader,
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                    ],
                    header: (ctx) => {
                        if (store.state.boardsState.boards && ctx?.route) {
                            const currentBoard = store.state.boardsState.boards.find((b) => TokenId.getId(b.board) === ctx.route.params[ EntityTypes.BOT_ID ])

                            if (currentBoard) {
                                return currentBoard.title
                            }

                            throw new Error('Board not found')
                        }
                        throw new Error('Boards are not initialised or context route is null')
                    },
                    avatar: (ctx) => {
                        if (store.state.boardsState.boards && ctx?.route) {
                            const currentBoard = store.state.boardsState.boards.find((b) => TokenId.getId(b.board) === ctx.route.params[ EntityTypes.BOT_ID ])

                            if (currentBoard) {
                                return currentBoard.avatar
                            }

                            throw new Error('Board not found')
                        }
                        throw new Error('Boards are not initialised or context route is null')
                    },
                    class: [ 'empty' ],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-settings',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Common_settings',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/settings`,
                            component: (): any => import('./views/board-settings/common-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_common_settings_title') }
                                    ]
                                }
                            }
                        },
                    },
                    class: [
                        () => {
                            if (store.state.boardsState.activeBoard) {
                                return [ TestBoardStateCode.ApiError, TestBoardStateCode.BotDomainError ].includes(store.state.boardsState.activeBoard.test_state)
                                    ? 'bot-state-icon'
                                    : ''
                            } else {
                                return ''
                            }
                        },
                        () => {
                            if (!store.getters.hasBoardLicense) {
                                return 'bot-license-icon'
                            } else {
                                return ''
                            }
                        }
                    ],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-dollar-sign',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'OffersList',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/ads`,
                            component: (): any => import('./views/board-settings/offers-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_offerslist_title') },
                                    ]
                                }
                            }
                        },
                    },
                    tag: (): string => "New",
                    tagColor: () => 'rgba(var(--a-success))',
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    icon: 'icon-dollar-sign',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'OffersListItem',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/ads/:adId?/:actionType`,
                            component: (): any => import('./views/board-settings/offers-list-item.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_offerslist_title'), routeName: 'OffersList' },
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                }
                            },
                            beforeEnter: (to, from, next) => {
                                if (!Object.values(BaseItemViewAction).includes(to.params.actionType as BaseItemViewAction)) {
                                    next({
                                        name: 'OffersList', params: {
                                            [ EntityTypes.BOT_ID ]: to.params[ EntityTypes.BOT_ID ]
                                        }
                                    })
                                }

                                next()
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-radio',
                    isVisible: [
                        (ctx: any): boolean => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channels',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channels`,
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channels_title') },
                                    ]
                                },
                            },
                            component: (): any => import('./views/channels.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-message-square',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Bot_feedback',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/feedback`,
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_bot_feedback_title') }
                                    ]
                                }
                            },
                            component: (): any => import('./views/board-settings/bot-feedback.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-calendar',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'posts_planner',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }`,
                            component: (): any => import('./views/posts-planner.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_posts_planner_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-rss',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Distributions',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/distributions/`,
                            component: (): any => import('./views/distributions-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_distributions_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-rss',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'FlowMasters',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/flow-masters/`,
                            component: (): any => import('./views/flow-masters.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('flowmasters_page_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-users',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Users_options',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/users`,
                            component: (): any => import('./views/Users/Users.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_users_options_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        () => hasChangeConfigPermission(),
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                    ],
                    tag: (): string => store.state.boardsState.activeBoard?.license_expired ? 'Prime' : '',
                    tagColor: () => '#f75357',
                    icon: 'icon-git-pull-request',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Flows_List',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/flows-list`,
                            component: (): any => import('./views/Flow/FlowsList.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_flows_list_title') }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        () => hasChangeConfigPermission(),
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => {
                            const board = store.state.boardsState?.activeBoard

                            if (board) {
                                const labels = board.config.labels
                                const properties = board.properties

                                return !!Object.keys(labels).length || properties.length > 0
                            }

                            return false
                        },
                    ],
                    icon: 'icon-archive',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Bot_Properties',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/bot-properties`,
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_bot_properties_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/BotProperties/BotPropertiesView.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        () => hasChangeConfigPermission(),
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => store.getters.hasCashRegisters,
                    ],
                    icon: 'icon-shopping-cart',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Shop',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/shop`,
                            component: (): any => import('./views/shop/shop.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_shop_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-folder',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => canCreatePost(),
                        () => store.getters.hasDrafts,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Drafts_list',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/drafts/`,
                            component: (): any => import('./views/drafts-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_drafts_list_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                        () => {
                            const board = store.state.boardsState?.activeBoard

                            if (board) {
                                const styles = board.config.styles

                                return !!styles && !!Object.keys(styles).length
                            }

                            return false
                        },
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Styles',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/styles`,
                            component: (): any => import('./views/styles.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_styles_title') },
                                    ]
                                },
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-edit',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => store.getters.hasForms,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Forms',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/forms`,
                            component: (): any => import('./views/Forms/form-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_forms_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-file-text',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && !ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                        () => store.getters.hasArticles,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'articles_list',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/articles-list`,
                            meta: {
                                disableLoader: true,
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_articles_list_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/articles-list.vue')
                        }
                    },
                    class: [],
                }
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    featherIcon: true,
                    icon: 'icon-shopping-bag',
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Buy_board',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/buy`,
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_board_personal_title') },
                                        { label: i18n.t('menu_buy_board_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/buy-board.vue'),
                        },
                    },
                    'class': [ 'vu-nav-license' ],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'User_profile',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/user-profile/:user_id`,
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_board_personal_title') },
                                        { label: i18n.t('menu_users_options_title'), routeName: 'Users_options' },
                                        { label: i18n.t('menu_user_profile_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/Users/Profile.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'Flow',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/flow/:actionType`,
                            query: {},
                            component: (): any => import('./views/Flow/NewFlow.vue'),
                        },
                    },
                    class: [],
                },
            },
            /* deprecated route, it redirects now to /flow, later could be removed */
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'New_Flow',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/new-flow/:actionType`,
                            query: {},
                            component: (): any => import('./views/Flow/NewFlow.vue'),
                            beforeEnter: (to, from, next) => {
                                next({ name: "Flow", params: to.params, query: to.query, replace: true })
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'shared',
                            path: '/shared/article/:articleKey',
                            meta: {
                                requiresAuth: false
                            },
                            component: (): any => import('./views/article-read-only.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'Flow_Screenshot',
                            path: '/flow-screenshot/:request',
                            meta: {
                                requiresAuth: false
                            },
                            component: (): any => import('./views/Flow/NewFlow.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-clipboard',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'article',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/articles-list/:actionType`,
                            component: (): any => import('./views/article.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_articles_list_title'), routeName: 'articles_list' },
                                        ...ctx.route.query.articleKey ? [ { label: TokenId.getId(ctx.route.query.articleKey.toString()) } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Style',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/styles/:actionType`,
                            component: (): any => import('./views/style.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_styles_title'), routeName: 'Styles' },
                                        ...ctx.route.query.styleId ? [ { label: TokenId.getId(ctx.route.query.styleId.toString()) } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Distribution',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/distributions/:distributionId?/:actionType`,
                            component: (): any => import('./views/distribution-item.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_distributions_title'), routeName: 'Distributions' },
                                        ...ctx.route.params.distributionId && ctx.route.params.actionType !== BaseItemViewAction.Copy ? [ { label: TokenId.getId(ctx.route.params.distributionId.toString()) } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            },
                        },
                    },
                    class: [],

                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Full_form',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/forms/:formKey?/:actionType`,
                            component: (): any => import('./views/Forms/form-item.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_forms_title'), routeName: 'Forms' },
                                        ...ctx.route.params.formKey ? [ { label: TokenId.getId(ctx.route.params.formKey.toString()) } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            },
                            beforeEnter: (to, from, next) => {
                                if (!Object.values(BaseItemViewAction).includes(to.params.actionType as BaseItemViewAction)) {
                                    next({
                                        name: 'Forms', params: {
                                            [ EntityTypes.BOT_ID ]: to.params[ EntityTypes.BOT_ID ]
                                        }
                                    })
                                }

                                next()
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'Last_form_answer',
                            path: `last-answer/:token`,
                            component: (): any => import('./views/Forms/last-form-answer.vue'),
                            meta: {
                                requiresAuth: false
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'FormPublic',
                            path: '/questionnaire/:formToken',
                            component: (): any => import('./views/form-public.vue'),
                            meta: {
                                requiresAuth: false,
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.DynamicHeader,
                    to: { name: 'Channel_main_settings' },
                    isVisible: [
                        (ctx) => !!ctx && !!ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission()
                    ],
                    header: (ctx) => store.state.channelsState.activeChannel ? store.state.channelsState.activeChannel.title : '',
                    avatar: (ctx) => store.state.channelsState.activeChannel ? store.state.channelsState.activeChannel.avatar : '',
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-calendar',
                    isVisible: [
                        (ctx) => store.getters.isBoardSet,
                        (ctx) => !!ctx && ctx.route.params[EntityTypes.CHANNEL_ID],
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'posts_planner',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }`,
                            component: (): any => import('./views/posts-planner.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_posts_planner_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-radio',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission()
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_main_settings',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }`,
                            component: (): any => import('./views/channel/channel-main-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_main_settings_title') }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-message-circle',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission()
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_comments',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/comments-settings`,
                            component: (): any => import('./views/channel/comments-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_comments_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-send',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Publish_settings',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/publish-settings`,
                            component: (): any => import('./views/channel/publish-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_publish_settings_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-shield',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission()
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_protection',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/protection`,
                            component: (): any => import('./views/channel/channel-protection.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_protection_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-sliders',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'approve_balancer',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/approve-balancer`,
                            component: (): any => import('./views/channel/approve-balancer-page.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_approve_balancer_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-eye',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_face_control',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/face-control`,
                            component: (): any => import('./views/channel/face-control.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_face_control_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission()
                    ],
                    icon: 'icon-users',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_subscription',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/subscription`,
                            component: (): any => import('./views/channel/channel-subscription.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_subscription_title') }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx && !!ctx.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission()
                    ],
                    icon: 'icon-link',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'invite_links_list',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/link-list`,
                            query: {},
                            component: (): any => import('./views/invite-links/link-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_invite_links_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.DropDown,
                    guid: Guid.create().toString(),
                    name: 'Statistics_all',
                    isVisible: [
                        (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                        () => hasChangeConfigPermission(),
                    ],
                    featherIcon: true,
                    icon: 'icon-bar-chart',
                    submenu: [
                        {
                            menuItem: {
                                type: MenuItemType.Route,
                                featherIcon: true,
                                icon: 'icon-bar-chart-',
                                isVisible: [
                                    (ctx) => !!ctx?.route.params[EntityTypes.CHANNEL_ID],
                                    () => hasChangeConfigPermission()
                                ],
                                routeConfig: {
                                    layout: Layout.Panel,
                                    config: {
                                        name: 'Channel_statistics',
                                        path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/statistics`,
                                        component: (): any => import('./views/channel/channel-statistics.vue'),
                                        meta: {
                                            breadcrumbs: (ctx) => {
                                                return [
                                                    ...baseBoardBreadcrumbs(ctx.route),
                                                    ...baseChannelBreadcrumbs(ctx.route),
                                                    { label: i18n.t('menu_statistics_all_title') },
                                                    { label: i18n.t('menu_channel_statistics_title') },
                                                ]
                                            }
                                        },
                                    },
                                },
                                class: [],
                            },
                        },
                        {
                            menuItem: {
                                type: MenuItemType.Route,
                                isVisible: [ true ],
                                icon: 'icon-bar-chart-',
                                featherIcon: true,
                                routeConfig: {
                                    layout: Layout.Panel,
                                    config: {
                                        name: 'Invite_links_statistics',
                                        path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/link-statistics`,
                                        query: {},
                                        component: (): any => import('./views/invite-links/link-statistics.vue'),
                                        meta: {
                                            breadcrumbs: (ctx) => {
                                                return [
                                                    ...baseBoardBreadcrumbs(ctx.route),
                                                    ...baseChannelBreadcrumbs(ctx.route),
                                                    { label: i18n.t('menu_statistics_all_title') },
                                                    { label: i18n.t('menu_invite_links_statistics_title') },
                                                ]
                                            }
                                        },
                                    },
                                },
                                class: [],
                            },
                        },
                    ],
                }
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'LayersIcon',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'post',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/post/:actionType`,
                            component: (): any => import('./views/post.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    let label = ''

                                    if (ctx?.route.params.actionType === 'new') label = i18n.t('new_post').toString()
                                    if (ctx?.route.params.actionType === 'copy') label = i18n.t('new_post').toString()
                                    if (ctx?.route.params.actionType === 'edit') label = i18n.t('edit_post').toString()
                                    if (ctx?.route.params.actionType === 'edit-published') label = i18n.t('edit_published_post').toString()
                                    if (ctx?.route.params.actionType === 'show-suggest') label = i18n.t('show_suggest').toString()
                                    if (ctx?.route.params.actionType === 'edit-suggest') label = i18n.t('edit_suggest').toString()

                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_posts_planner_title'), routeName: 'posts_planner' },
                                        { label }
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-shopping-bag',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Buy_channel',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/buy`,
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_buy_channel_title') }
                                    ]
                                }
                            },
                            component: (): any => import('./views/buy-channel.vue'),
                        },

                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'invite_link',
                            path: `/board/:${ [ EntityTypes.BOT_ID ] }/channel/:${ [ EntityTypes.CHANNEL_ID ] }/link/:actionType`,
                            query: {},
                            component: (): any => import('./views/invite-links/link-page.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_invite_links_title'), routeName: 'invite_links_list' },
                                        ...ctx.route.query.linkCode ? [ { label: TokenId.getId(ctx.route.query.linkCode.toString()) } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
        ],
        globalGuards: {
            afterEach: async (to) => {
                const boardId = to.params[ EntityTypes.BOT_ID ]
                const channelId = to.params[EntityTypes.CHANNEL_ID]

                if (!boardId)  {
                    await store.dispatch('clearBotStores')
                }

                if (!channelId) {
                    await store.dispatch('reset_active_channel')

                    await store.dispatch('resetInviteLinks')
                }
            },
            beforeEach: async (to, from, next) => {
                const boardId = to.params[ EntityTypes.BOT_ID ]
                const channelId = to.params[EntityTypes.CHANNEL_ID]

                const getChannel = async (next: NavigationGuardNext, channelId: SlimChannelInfo['id'], board: SlimBoardInfo['board'], boardId: string) => {
                        const channel = await store.dispatch('getChannelConfig', {
                            board_key: board,
                            channel_id: channelId
                        })

                        if (channel) {
                            await store.dispatch('updateTargetsWithoutLinkedGroup', [ channelId ])

                            store.commit('updateValidateCommentButtonHasGroup', true)
                        } else {
                            store.commit('updateValidateCommentButtonHasGroup', false)

                            next({ name: 'Channels', params: { [EntityTypes.BOT_ID]: boardId } })
                        }
                }

                if (hasMatchedRouteParams(to)) {
                    next()

                    return
                }

                if (!store.state.boardsState.boards) {
                    await store.dispatch('requestBoards')
                }

                if (boardId && !store.getters.isBoardSet) {
                    const currentBoard: SlimBoardInfo | undefined = store.getters.getBoardById(boardId)

                    if (currentBoard) {
                        const board = await store.dispatch('getBoardConfig', currentBoard.board)

                        if (board) {
                            store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', boardId)

                            if (!store.state.menuOptionsState.menuOptions && hasChangeConfigPermission()) {
                                await store.dispatch('getMenuOptions')
                            }

                            if (channelId && !store.getters.isChannelSet) {
                                await getChannel(next, +channelId, board.board, boardId)
                            }
                        } else {
                            next({ name: 'Boards' })
                        }
                    } else {
                        errorNotification(i18n.t('bot_not_found_error_message').toString())

                        next({ name: 'Boards' })
                    }
                } else if (store.getters.isBoardSet && channelId && !store.getters.isChannelSet) {
                    const currentBoard: SlimBoardInfo | undefined = store.getters.getBoardById(boardId)

                    if (currentBoard) {
                        await getChannel(next, +channelId, currentBoard.board, boardId)
                    }
                }

                next()
            },
        },
        onSaveButtonClick: (ctx, wasClicked): void => {
            if (wasClicked) {
                if (ctx.route.params[EntityTypes.CHANNEL_ID]) {
                    store.dispatch('save_active_channel_config', ctx.route)
                } else {
                    store.dispatch('savePostBoardConfig')
                }
            }
        }
    },
    router: router,
    store: store,
}

Vue.use(AdminTemplate as unknown as PluginFunction<any>, adminTemplateOptions)

Vue.config.productionTip = false;

(window as any).mobilecheck = isMobile

Vue.use(VueCustomElement)

Vue.customElement('my-element', (PostFeed.prototype.constructor as any).options)

export default new Vue({
    router,
    i18n,
    store,
    'render': (h): VNode => h(App),
}).$mount('#app')
