var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
    'func': _vm.modelSetter,
    'args': {
      'model': _vm.postMessage,
      'key': 'variants',
      'availableButtonsTypes': _vm.inputButtons,
      'settingsStructure': _vm.inputSettingsStructure,
      'validation': 'required|editor_not_empty',
      'base-api-url': 'api.presscode.app/cabinet',
      'targetToUpload': {
        'target': 'target',
        'target_id': _vm.$store.state.boardsState.activeBoard.board
      },
      'buttonsHelpView': _vm.EditorButtonsHelpView,
      'availableTabs': [ _vm.MessageEditorWithMediaTab.EditorTab, _vm.MessageEditorWithMediaTab.TextareaTab ].concat( _vm.extraTabs ),
      'disabled': _vm.disabled
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }